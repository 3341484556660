<template>
  <div class="py-10" :class="{ 'bg-f9 px-20': mode === 'update' }">
    <!-- update -->
    <a-form-model
      ref="container"
      :model="model"
      labelAlign="left"
      :colon="false"
    >
      <gf-form-item prop="IsMember" label="是否为会员">
        <a-select
          :disabled="mode === 'display'"
          v-model="model.IsMember"
          placeholder="请选择"
        >
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </gf-form-item>
      <gf-form-item prop="VisitedAt" label="拜访日期">
        <a-date-picker
          :disabled="mode === 'display'"
          v-model="model.VisitedAt"
          placeholder="请选择年/月/日"
          valueFormat="x"
        ></a-date-picker>
      </gf-form-item>
      <gf-form-item prop="VisitAddress" label="地点">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.VisitAddress"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <gf-form-item
        prop="VisitSubject"
        label="事由"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请输入"
          :rows="5"
          :max="150"
          v-model="model.VisitSubject"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item prop="CompanyParticipants" label="企业参与人">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.CompanyParticipants"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <gf-form-item prop="Participants" label="协会参与人">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.Participants"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <gf-form-item prop="Contact" label="联系人">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.Contact"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <gf-form-item prop="Title" label="职务">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.Title"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <gf-form-item prop="Phone" label="联系电话">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.Phone"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <gf-form-item prop="Email" label="邮箱">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.Email"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <gf-form-item
        prop="Proposal"
        label="意见建议"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请输入"
          :rows="5"
          :max="150"
          v-model="model.Proposal"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="Matters"
        label="后续可跟进事宜"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请输入"
          :rows="5"
          :max="150"
          v-model="model.Matters"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item prop="Recorder" label="记录人">
        <gf-re-input
          :disabled="mode === 'display'"
          v-model="model.Recorder"
          placeholder="请输入"
        ></gf-re-input>
      </gf-form-item>
      <div>近期重点沟通内容</div>
      <gf-form-item
        prop="CommContent1"
        label="1、企业数字化转型的遇到问题和瓶颈"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="如：政策需求、政府渠道、寻找客户、寻找合作伙伴、人才需求、品牌市场宣传需求、资本、法律知识产权服务等。"
          :rows="5"
          :max="150"
          v-model="model.CommContent1"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="CommContent21"
        label="2-1、了解企业数字化转型的需求"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          :placeholder="`请填写`"
          :rows="5"
          :max="150"
          v-model="model.CommContent21"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="CommContent22"
        label="2-2、供需对接情况"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          :placeholder="`请填写`"
          :rows="5"
          :max="150"
          v-model="model.CommContent22"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="CommContent22"
        label="2-2、供需对接情况"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          :placeholder="`请填写`"
          :rows="5"
          :max="150"
          v-model="model.CommContent22"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="CommContent3"
        label="3、了解工赋诸葛会相关工作参与度"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          :placeholder="`请填写`"
          :rows="5"
          :max="150"
          v-model="model.CommContent3"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="CommContent4"
        label="4、了解企业人才建设的需求"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          :placeholder="`请填写`"
          :rows="5"
          :max="150"
          v-model="model.CommContent4"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="CommContent5"
        label="5、了解企业市场活动品牌宣传的需求"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          :placeholder="`请填写`"
          :rows="5"
          :max="150"
          v-model="model.CommContent5"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="CommContent6"
        label="6、会员单位情况更新"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请填写"
          :rows="5"
          :max="150"
          v-model="model.CommContent6"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item prop="Photos" label="企业照片">
        <pictures-upload
          :disabled="mode === 'display'"
          v-model="model.Photos"
          :width="330"
          :height="248"
          accept=".png,.jpg,.jpeg"
          :size="5"
          field="6"
        ></pictures-upload>
      </gf-form-item>
      <gf-form-item
        prop="Opinion"
        label="交流发言观点"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请填写"
          :rows="5"
          :max="150"
          v-model="model.Opinion"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item prop="CanVisit" label="是否有展厅或产线提供参观">
        <a-select
          v-model="model.CanVisit"
          placeholder="请选择"
          :disabled="mode === 'display'"
        >
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </gf-form-item>
      <gf-form-item
        prop="Intro"
        label="企业介绍"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请填写"
          :rows="5"
          :max="150"
          v-model="model.Intro"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="VisitExp"
        label="企业参观体会"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请填写"
          :rows="5"
          :max="150"
          v-model="model.VisitExp"
        ></gf-re-textarea>
      </gf-form-item>
      <gf-form-item
        prop="Summary"
        label="经验总结"
        :rules="[
          {
            max: 150,
            message: '字数超出限制',
            trigger: ['change', 'blur'],
          },
        ]"
      >
        <gf-re-textarea
          :disabled="mode === 'display'"
          placeholder="请填写"
          :rows="5"
          :max="150"
          v-model="model.Summary"
        ></gf-re-textarea>
      </gf-form-item>
    </a-form-model>
    <div class="text-right">
      <slot name="update" v-bind="{ validate }"></slot>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    mode: {
      type: String,
      default: "display",
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            const temp = this.restore(this.model);

            resolve(temp);
          } else {
            reject();
          }
        });
      });
    },
    transform(data) {
      const temp = { ...data };

      temp.CanVisit = temp.CanVisit === undefined ? undefined : +temp.CanVisit;
      temp.IsMember = temp.IsMember === undefined ? undefined : +temp.IsMember;
      temp.VisitedAt = moment(data.VisitedAt);
      temp.VisitedAtStr = temp.VisitedAt.format("YYYY-MM-DD");

      return temp;
    },
    restore(data) {
      const temp = { ...data };

      temp.CanVisit = temp.CanVisit === undefined ? undefined : !!temp.CanVisit;
      temp.IsMember = temp.IsMember === undefined ? undefined : !!temp.IsMember;
      temp.VisitedAt = new Date(+temp.VisitedAt).toISOString();

      return temp;
    },
  },
  created() {
    const temp = this.transform(this.dataSource);

    this.model = temp;
  },
  watch: {
    dataSource(val) {
      const temp = this.transform(val);

      this.model = temp;
    },
  },
};
</script>