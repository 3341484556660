<template>
  <div class="container mx-auto text-left">
    <div class="text-center py-40">
      <a-input-search
        style="width: 580px"
        size="large"
        enter-button
        v-model="name"
        @search="search"
      ></a-input-search>
    </div>
    <FilterSelect
      class="mb-40"
      :filterOptions="filterOptions"
      @change="filter"
    ></FilterSelect>
    <pagination-list
      type="company-partner"
      :gutter="20"
      :size="3"
      :total="total"
      :dataSource="computedDataSource"
      :pagination.sync="pagination"
      @change="jump"
    ></pagination-list>
  </div>
</template>

<script>
import { getOpCompanys } from "@/api/company.js";
import { upload } from "@/api";
export default {
  data() {
    return {
      fitlerParams: {},
      filterOptions: [
        {
          name: "企业性质",
          flag: "CompanyProperty",
          restore: "property",
        },
        {
          name: "所属行业",
          flag: "CompanyIndustry",
          restore: "industry",
        },
        {
          name: "企业规模",
          flag: "CompanyScale",
          restore: "scale",
        },
      ],
      selected: [],
      pagination: {
        limit: 12,
        offset: 0,
      },
      total: 0,
      name: "",
      data: [],
    };
  },
  inject: ["options"],
  created() {
    this.getList();
  },
  computed: {
    computedDataSource() {
      const industry = this.options.industry;
      const tag = this.options.tag;
      return this.data.map((item) => {
        const temp = {
          productCount: 0,
          requirementCount: 0,
          caseCount: 0,
          tags: [],
          name: "",
          value: "",
        };
        temp.id = item.ID;
        temp.isTech = item.IsTech;
        if (item.Detail && item.Detail.Info) {
          const info = item.Detail.Info;
          temp.name = info.Name;
          temp.shortName = info.ShortName;
          if (info.Logo && info.Logo.ID) {
            temp.logo = upload() + "/" + info.Logo.ID;
          }
        }
        if (item.Detail && item.Detail.State) {
          const State = item.Detail.State;
          temp.value = State.Values;
        }
        if (item.Detail && item.Detail.Label) {
          const industries = item.Detail.Label.Industries || [];
          const tags = item.Detail.Label.Tags || [];

          temp.tags = industries
            .map((item) => {
              if (item.ID === 0 && item.Name) {
                return item;
              } else {
                return industry[item.ID - 1];
              }
            })
            .filter((item) => item);
          temp.tags1 = tags
            .map((item) => {
              if (item.ID === 0 && item.Name) {
                return item;
              } else {
                return tag[item.ID - 1];
              }
            })
            .filter((item) => item);
        }
        if (item.Stats) {
          temp.productCount = item.Stats.Products;
          temp.caseCount = item.Stats.Cases;
          temp.requirementCount = item.Stats.Requirements;
          temp.solutionCount = item.Stats.Solutions;
        }
        return temp;
      });
    },
  },
  methods: {
    generateParams() {
      const temp = {
        name: this.name,
      };
      Object.assign(temp, this.pagination, this.fitlerParams);
      return temp;
    },
    getCompanyList(data) {
      getOpCompanys(data).then(({ data }) => {
        this.total = data.count;
        this.data = data.rows || [];
      });
    },
    getList() {
      const temp = this.generateParams();
      this.getCompanyList(temp);
    },
    search() {
      this.$set(this.pagination, "offset", 0);
      this.getList();
    },
    filter(e) {
      this.$set(this.pagination, "offset", 0);
      this.fitlerParams = e;
      this.getList();
    },
    jump() {
      this.getList();
    },
  },
};
</script>