<template>
  <div class="bg-white p-20">
    <div class="text-xl font-bold">{{ name }}</div>
    <a-tabs>
      <a-tab-pane tab="对接情况" key="1">
        <survey :mode="survey.mode" :dataSource="survey">
          <a-space slot="update" slot-scope="{ validate }">
            <a-button
              @click="updateState(survey, 'display')"
              v-if="survey.mode === 'update'"
              type="primary"
              ghost
              >取消</a-button
            >
            <a-button
              @click="saveSurvey(validate)"
              type="primary"
              v-if="survey.mode === 'update'"
              >保存</a-button
            >
            <a-button
              v-if="survey.mode === 'display'"
              type="primary"
              @click="updateState(survey, 'update')"
              >修改</a-button
            >
          </a-space>
        </survey>
      </a-tab-pane>
      <a-tab-pane tab="数字化情况" key="2" v-if="company.IsTech === false">
        <Digitalize :mode="digitalize.mode" :dataSource="digitalize">
          <a-space slot="update" slot-scope="{ validate }">
            <a-button
              @click="updateState(digitalize, 'display')"
              type="primary"
              ghost
              >取消</a-button
            >
            <a-button @click="saveProduct(validate)" type="primary"
              >保存</a-button
            >
          </a-space>
        </Digitalize>
        <div class="text-right">
          <a-space>
            <a-button
              v-if="digitalize.mode === 'display'"
              type="primary"
              @click="updateState(digitalize, 'update')"
              >修改</a-button
            >
          </a-space>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { getProCompany } from "@/api/company.js";
import { updateDigital, updateSurvey } from "@/api";
import Digitalize from "@/views/admin/state/components/Digitalize.vue";
import survey from "./components/survey.vue";
export default {
  components: { Digitalize, survey },
  data() {
    return {
      name: "",
      digitalize: {},
      survey: {},
      company: {},
    };
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.getProCompany(id);
  },
  methods: {
    getProCompany(id) {
      getProCompany(id).then(({ data }) => {
        this.company = data;
        this.name = data.Detail.Info.Name;
        const digitalize = { ...data.Digital, mode: "display" };
        const survey = { ...data.Survey, mode: "display" };
        this.digitalize = digitalize;
        this.survey = survey;
      });
    },
    // 修改状态
    updateState(data, state) {
      this.$set(data, "mode", state);
    },
    // 保存数字化情况
    saveProduct(validate) {
      validate().then((res) => {
        this.updateDigital(res);
      });
    },
    // 更新数字化情况
    updateDigital(data) {
      updateDigital(this.id, data).then(() => {
        this.$message.success("更新成功");
        this.getProCompany(this.id);
      });
    },
    // 保存对接情况
    saveSurvey(validate) {
      validate().then((res) => {
        this.updateSurvey(res);
      });
    },
    // 更新对接情况
    updateSurvey(data) {
      updateSurvey(this.id, data).then(() => {
        this.$message.success("更新成功");
        this.getProCompany(this.id);
      });
    },
  },
};
</script>